import { format, parseISO } from "date-fns";
import { NavLink } from "react-router-dom";

function NewsHeroCard({ title, id, coverImg }) {
  return (
    <NavLink
      to={`/news/${id}`}
      className="group w-[340px] h-fit flex flex-col lg:gap-[20px] gap-[12px] items-start"
    >
      <div className="w-full h-[215px] lg:h-[215px] overflow-hidden">
        <img
          src={coverImg}
          alt="blog-cover-img"
          className="w-full h-[215px] object-cover group-hover:scale-[1.1] transform transition-transform ease-in-out duration-300" />
      </div>

      <div
        className="flex flex-col items-start"
        style={{ alignSelf: "normal" }}
      >
        <p className="group-hover:text-[#3E6BE4] h-fit transition-colors ease-out duration-300 line-clamp-3 text-[12px] lg:text-[14px] text-black lg:leading-[20px] leading-[16px] font-semibold">
          {title}
        </p>
      </div>
    </NavLink>
  );
}

export default NewsHeroCard;
