import link from "../../assets/linkArr.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

function NewsReUse({
  news,
  title,
  navLink,
  navLinkTitle,
  hideNavLink = false,
}) {
  const locale = useTranslation("home").i18n.language;

  const getNewsLabel = (label) => {
    switch (label) {
      case "Finansijska Terminologija":
        return locale === "me" ? "Finansijska Terminologija" : "Financial Terminology";
      case "Finansijsko Osvjescivanje":
        return locale === "me" ? "Finansijsko Osvjescivanje" : "Financial Awerness";
      case "Ostalo":
        return locale === "me" ? "Ostale vijesti" : "Other news";
      default:
        return "Vesti";
    }
  };

  return (
    <div className="w-full">
      {/* Container */}
      <div className="container mx-auto  lg:px-[20px] px-[16px]">
        {/* Current Affairs section */}
        <div className="py-[52px]">
          {/* 1/3 section */}
          <div className="flex justify-between mb-[48px]">
            <h1 className="text-[18px] leading-[30px] text-[#000] font-medium ">
              {title}
            </h1>
            {!hideNavLink && (
              <div className="lg:flex hidden gap-[12px] cursor-pointer">
                <NavLink
                  to={navLink}
                  className="text-[14px] leading-[30px] text-[#3E6BE4] font-semibold cursor-pointer"
                >
                  {navLinkTitle}
                </NavLink>
                <img
                  src={link}
                  alt="link-arrow"
                  className="cursor-pointer w-[9.75px]"
                ></img>
              </div>
            )}
          </div>
          {/* 2/3 section */}
          <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-[22px] xl:gap-[13px]">
            {news.map((newsItem, index) => (
              <NavLink
                to={`/news/${newsItem.id}`}
                key={index}
                className="cursor-pointer overflow-hidden group flex flex-col relative"
              >
                <span className="z-20 absolute top-0 left-0 px-[19px] py-[7px] text-[10px] leading-normal font-bold text-white bg-[#31435A]">
                  {getNewsLabel(newsItem.subcategory)}
                </span>
                <div className="h-[166px] w-full overflow-hidden">
                  <img
                    className="w-full h-full object-cover group-hover:scale-[1.1] transform transition-transform ease-in-out duration-300"
                    src={newsItem.full_photo_path || ""}
                    alt="news-img"
                  ></img>
                </div>
                <p className="group-hover:text-[#3E6BE4] transition-colors ease-out duration-300 text-[14px] leading-[20px] text-black font-medium pt-[16px] mb-[8px] cursor-pointer">
                  {newsItem.title[locale]}
                </p>
                <p className="text-[10px] leading-[30px] font-bold text-[#788497]">
                  {format(parseISO(newsItem.created_at), "dd.MM.yyyy.")}
                </p>
              </NavLink>
            ))}
          </div>
          {!hideNavLink && (
            <div className="mt-[36px] items-center justify-center flex lg:hidden gap-[12px] cursor-pointer">
              <NavLink
                to={navLink}
                className="text-[14px] leading-[30px] text-[#3E6BE4] font-semibold cursor-pointer"
              >
                {navLinkTitle}
              </NavLink>
              <img
                src={link}
                alt="link-arrow"
                className="cursor-pointer w-[9.75px]"
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsReUse;
